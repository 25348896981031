.app__footer {
  flex: 1;
  width: 100%;
  padding-top: 6rem;
  flex-direction: column;

  background-color: var(--backgroundLight-color);
}

.contact__form-container {
  box-shadow: 0 7px 29px 0 rgba(0, 0, 0, 0.2) ;
  background:  var(--backgroundDark-color);
  padding: 4rem;
  max-width: 40rem;
  width: 80%;
  border-radius: 20px;
  margin: 2rem auto 0 auto;

  @media screen and (max-width: 768px) {
    padding: 0rem;
    background-color: transparent;
    box-shadow: none;
  }
}

.app__footer-form {

  display: flex;
  flex-direction: column;

  div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin: 0.75rem 0;

    label {
      color: var(--white-color);

      @media screen and (max-width: 768px) {
        color: var(--black-color);
      }

    }

    input,
    textarea {
      width: 100%;
      padding: 0.95rem;
      border: none;
      border-radius: 7px;
      background-color: var(--white-color);

      font-family: var(--font-base);
      font-size: 1rem;
      color: var(--black-color);
      outline: none;

      &:hover {
        box-shadow: 0 0 15px var(--white-color);
      }
    }

    textarea {
      height: 170px;
    }
  }

  .button-container {
    display: flex;
    align-items: flex-end;

    p {
      color: var(--darkRed-color);
      font-weight: 500;
      font-size: 1.2rem;
    }
  }

  button {

    width: 10rem;
    padding: 1rem 2rem;
    border-radius: 10px;
    border: none;
    background-color: var(--secondary-color);

    font-weight: 500;
    color: var(--white-color);
    outline: none;
    margin: 2rem 0 0 0;
    font-family: var(--font-base);

    transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    cursor: pointer;

    &:hover {
      background-color: var(--backgroundLight-color);
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 1rem 0;
    }
  }
}

.contact__form-sent {
  color: var(--white-color);
  text-transform: uppercase;
  font-weight: 500;
  transition: all 0.3s ease-in-out;

  @media screen and (max-width: 768px) {
    margin-bottom: 5rem;
    color: var(--black-color);
  }
}

.app__footer-copyright {
  width: 100%;
  background-color: var(--backgroundDark-color);
  color: var(--white-color);
  font-weight: 500;
  margin-top: 3rem;
  padding: 3rem 0;

  a {
    color: var(--white-color);
    text-decoration: none;
    flex-direction: column;
    font-size: 1rem;
    font-weight: 800;

    transition: all 0.3s ease-in-out;

    &:hover {
      color: var(--secondary-color);
    }
  }
}

