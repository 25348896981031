.app__about {
  flex: 1;
  width: 100%;
  // height: 90vh;
  flex-direction: column;

  background-color: var(--backgroundLight-color);

  padding: 6rem 2rem 5rem;
}

.about__content {
  margin-top: 2rem;
  padding: 0 2rem;
  gap: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
}

.about__content-title {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: var(--backgroundDark-color);
}

.about__content-main {
  flex:1;
}

.about__content-details {
  font-size: 1.2rem;

  span {
    color: var(--navy-color);
    font-weight: 600;
  }
}

.about__content-skills {
  flex: 1;
}

.skills {
  display: flex;
  flex-wrap: wrap;
}

.skills__skill {
  padding: 0.5rem 1rem;
  margin-bottom: 1.5rem;
  margin-right: 1.5rem;
  font-size: 1rem;
  background: rgba(153,153,153,.2);
  border-radius: 5px;
  font-weight: 600;
  color: #666;
  text-transform: uppercase;

  @media screen and (max-width: 450px) {
    padding: 0.2rem 0.5rem;
    margin-bottom: 1.5rem;
    margin-right: 0.5rem;
    font-size: 1rem;
    border-radius: 5px;
    font-weight: 500;
  }
}