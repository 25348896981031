@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@300;400;500;600;700&display=swap');

:root {
  --font-base: "Dosis", sans-serif;

  --backgroundDark-color:#102030;
  --backgroundLight-color: #6586711c;

  --primary-color: #edf2f8;
  --secondary-color: #FA9F42;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;

  --navy-color: #03103E;
  --beige-color:#CBC0AD;
  --saje-color:#B6BE9C;
  --darkRed-color:#9E2B25;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
