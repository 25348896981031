#home {
  // disable the default padding styles
  .app__wrapper {
    padding: 0;

    .copyright {
      display: none;
    }
  }
}

.app__header {
  flex: 1;
  width: 100%;
  height: 100vh;
  flex-direction: row;

  background-color: var(--backgroundDark-color);

  padding: 6rem 2rem 0rem;

  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }

  @media screen and (max-width: 450px) {
    padding: 6rem 1rem 2rem;
  }
}

.header-left {
  display: flex;
  position: relative;
  flex:1;
  flex-direction: column;
  align-items: center;
  color: var(--white-color);
  z-index: 1;

  h1 {
    margin-top: 1rem;
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: 3px;
    display: block;

    @media screen and (max-width: 450px) {
      font-size: 1rem;
    }
  }

  p {
    margin-top: 0.5rem;
    padding: 0 6rem;
    letter-spacing: 1px;
    text-align: center;
    font-size: 1.2rem;

    @media screen and (max-width: 900px) {
      margin-top: 1.5rem;
    }

    @media screen and (max-width: 450px) {
      font-size: 1rem;
      padding: 0 3rem;
    }
  }

  .blur {
    position: absolute;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    z-index: 0;
    filter: blur(60px);
    margin-left: -20rem;

    @media screen and (max-width: 900px) {
      margin-top: 4rem;
      margin-left: -10rem;
      transform: scale(0.5);
    }
  }

  @media screen and (max-width: 900px) {
    justify-content: center;
  }
}

.header-right {
  display: flex;
  position: relative;
  flex:1;
  flex-direction: column;
  z-index: 0;

  img:nth-child(1) {
    position: absolute;
    width: 400px;
  }

  img:nth-child(2) {
    position: absolute;
    width: 400px;
    margin-top: -3rem;
  }

  .blur {
    position: absolute;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    z-index: -1;
    filter: blur(60px);
    margin: 0 -15rem -15rem 0;
  }  

  @media screen and (max-width: 450px) {
    transform: scale(0.75);
  }

}